import React, {Component} from "react";
import API, {
	AbstractProduct,
	getErrorMessage,
	PaymentProvider,
	PurchaseProductEntry,
	redirectToPaymentPage
} from "../api/API";
import appContext from "../context/AppContext";
import {DataContextConsumer} from "../data/DataContext";
import './PaymentProviderSelectModal.css';

export type PaymentProviderSelectModalData = {

	purchaseParams: {
		playerName: string,
		targetPlayerName: string,
		email: string,
		promoCode?: string,
		entries: PurchaseProductEntry[]
	},
	price: number,
	onBack: () => void

}

type PaymentType = {
	id: string;
	name: string;
	provider: PaymentProvider;
}

const paymentTypes: PaymentType[] = [
	{id: 'card', name: 'Банковская карта', provider: PaymentProvider.TINKOFF},
	{id: 'sbp', name: 'Система быстрых платежей', provider: PaymentProvider.YOOKASSA},
	{id: 'yoo_money', name: 'ЮMoney', provider: PaymentProvider.YOOKASSA},
	{id: 'sberpay', name: 'SberPay', provider: PaymentProvider.YOOKASSA},
	{id: 'tinkoff', name: 'Т-Банк', provider: PaymentProvider.TINKOFF},
	{id: 'world', name: 'Международные карты (AMD)', provider: PaymentProvider.UNITPAY},
	{id: 'crypto', name: 'Криптовалюта', provider: PaymentProvider.TELEGRAM}
]

class PaymentProviderSelectModal extends Component<{data: PaymentProviderSelectModalData}> {

	state = {
		paymentType: paymentTypes[0] as PaymentType
	}

	render() {
		const {data} = this.props;

		return <DataContextConsumer children={ctx => {
			return <div className="modal payment-provider-select-modal">
				<button className="close" onClick={() => appContext.setPaymentProviderSelectModalData(undefined)}/>

				<h1>Способ оплаты</h1>

				<hr/>

				<div className="sides">
					<div className="left">
						<p>Твой ник:</p>
						<p className="primary">{data.purchaseParams.playerName}</p>

						{data.purchaseParams.playerName === data.purchaseParams.targetPlayerName ? null : <>
							<p>Покупка на ник:</p>
							<p className="primary">{data.purchaseParams.targetPlayerName}</p>
						</>}

						<p>Твоя корзина:</p>
						<p className="primary">
						{this.props.data.purchaseParams.entries.map((e, i) => {
							let product: AbstractProduct;

							if (e.setId !== undefined) {
								product = ctx.sets[e.setId!];
							} else {
								product = ctx.products[e.productId!];
							}

							return <div key={i}>- {product.name} x{e.multiplier}</div>;
						})}
						</p>
						<p>Указанная почта:</p>
						<p className="primary">{this.props.data.purchaseParams.email}</p>
						<p>Проверь введённые данные.</p>
						<p>Если всё верно — выбери удобный тебе способ оплаты. После оплаты покупка будет выдана на сервере в течение 30 сек.</p>
					</div>

					<div className="right">
						{paymentTypes.map(t => <div key={t.id} className={t.id === this.state.paymentType.id ? 'selected' : ''} onClick={() => this.setState({paymentType: t})}>
							<img src={"https://prostocraft.ru/payment_type_icons/" + t.id + "_" + (t.id === this.state.paymentType.id ? '1' : '0') + ".png"} alt={t.name} title={t.name}/>
						</div>)}
					</div>
				</div>

				<div className="controls">
					<button className="back" onClick={() => {
						appContext.setPaymentProviderSelectModalData(undefined);

						this.props.data.onBack();
					}}>Назад</button>
					<button className="buy" onClick={e => this.onClick(e)}>{this.state.paymentType.name}: {this.props.data.price} ₽</button>
				</div>

				<hr className="last"/>
			</div>;
		}}/>;
	}

	private onClick(e: any) {
		const params = this.props.data.purchaseParams;

		const button = (e.target as any);

		button.disabled = true;

		API.purchase(
			params.playerName,
			params.targetPlayerName,
			params.email,
			params.promoCode,
			params.entries,
			this.state.paymentType.provider,
			response => {
				button.disabled = false;

				if (response.error) {
					alert(getErrorMessage(response.error));
				} else {
					redirectToPaymentPage(response.response!.redirectUrl);
				}
			}
		);
	}
}

export default PaymentProviderSelectModal;
