import React, {Component} from 'react';
import {defaultPaymentProvider, Products, Sets} from '../api/API';
import {CartItem, toPurchaseEntries} from '../cart/Cart';
import GiftCheckbox from '../common/GiftCheckbox';
import PriceCalculator from '../common/PriceCalculator';
import TermsCheckbox from '../common/TermsCheckbox';
import appContext from '../context/AppContext';
import {DataContextConsumer} from '../data/DataContext';
import './PurchaseConfirmationModal.css';

let savedState = {
	playerName: '',
	isGift: false,
	targetPlayerName: '',
	promoCode: '',
	email: '',
	termsAccepted: false,
	paymentProvider: defaultPaymentProvider
};

type State = typeof savedState;

class PurchaseConfirmationModal extends Component<{purchasedProducts: CartItem[]}> {

	state: State = {...savedState, termsAccepted: false};

	render() {
		const {playerName, isGift, targetPlayerName, promoCode, email, termsAccepted} = this.state;

		return <DataContextConsumer children={(ctx) => {
			return <PriceCalculator playerName={isGift ? targetPlayerName : playerName} promoCode={promoCode} items={this.props.purchasedProducts} products={ctx.products} children={price =>
				<div className="modal purchase-confirmation-modal">
					<button className="close" onClick={() => appContext.setPurchasedProducts(undefined)}/>

					<h1>Покупка доната</h1>

					<hr/>

					<div className="products">
						<p>Товары:</p>
						<p>{this.formatProductList(ctx.products, ctx.sets)}</p>
					</div>

					<hr/>

					<div className="controls">
						<input placeholder="Ник" value={playerName} onChange={e => this.setState({playerName: e.target.value})}/>

						<input placeholder="Промокод" value={promoCode} onChange={e => this.setState({promoCode: e.target.value})}/>

						<input placeholder="Электронная почта" value={email} onChange={e => this.setState({email: e.target.value})}/>

						<GiftCheckbox checked={isGift} onChange={v => this.setState({isGift: v})}/>

						{isGift ? <input placeholder="Ник игрока, которому придёт товар" value={targetPlayerName} onChange={e => this.setState({targetPlayerName: e.target.value})}/> : null}

						<TermsCheckbox accepted={termsAccepted} onChange={v => this.setState({termsAccepted: v})}/>

						<button className="buy" disabled={!termsAccepted || playerName.trim() === '' || email.trim() === ''} onClick={() => this.onClick(price)}>
							Далее{price <= 0 ? '' : ` (${price} ₽)`}
						</button>
					</div>

					<hr className="last"/>
				</div>
			}/>;
		}}/>;
	}

	private formatProductList(products: Products, sets: Sets) {
		return this.props.purchasedProducts.map(e => {
			let name = e.productId !== undefined ?
				products[e.productId].name :
				sets[e.setId!].name;

			if (e.setting !== undefined) {
				if (e.productId !== undefined && products[e.productId].isDuration) {
					name += e.setting == -1 ?
						' (навсегда)' :
						` (${e.setting / 24 / 60 / 60} д.)`;
				} else {
					name += ` (${e.setting})`;
				}
			}

			if (e.multiplier !== 1) {
				name += ` (${e.multiplier} шт.)`;
			}

			return name;
		}).join(', ');
	}

	private onClick(price: number) {
		savedState = this.state;

		const prevProducts = appContext.purchasedProducts!;

		appContext.setPurchasedProducts(undefined);

		appContext.setPaymentProviderSelectModalData({
			purchaseParams: {
				playerName: this.state.playerName,
				targetPlayerName: this.state.targetPlayerName !== '' ?
					this.state.targetPlayerName :
					this.state.playerName,
				email: this.state.email,
				promoCode: this.state.promoCode === '' ? undefined : this.state.promoCode,
				entries: toPurchaseEntries(this.props.purchasedProducts)
			},
			price: price,
			onBack: () => {
				appContext.setPurchasedProducts(prevProducts);
			}
		});
	}
}

export default PurchaseConfirmationModal;
